import { baseURL, chainAsset, chainListApi } from "./constant";
import axios from 'axios';

export interface  clientAxiosProps {
    method : string,
    url : string
};

export const client = async ({method, url} : clientAxiosProps) :Promise<any> =>{
    const headers = {
        Accept: "*/*",
        "Content-Type": "application/x-www-form-urlencoded",
    };
  
    return await axios({ baseURL, url, method, headers})
        .then(({data}) =>  data)
        .catch((error: Error) =>console.log("error FAILED @ xx>>", url, error.message));
};


export const getChainList = async()=>{
    return await client({method : 'GET', url : chainListApi});
};



export const getChainAssetsList = async()=>{
    return await client({method : 'GET', url : chainAsset});
};