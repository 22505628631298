import { useEffect, useState } from "react";
import { chainListApi } from "./constant";
import "./styles.css";
import { chainApiType, chainAssetsType } from "./types";
import { getChainAssetsList, getChainList } from "./utils";

export default function App() {
  const [ listChainAPi, setListChain ] = useState<chainApiType[]>([]);
  const [ listChainAsset, setListAsset] = useState<chainAssetsType[]>([]);
  useEffect( ()=>{
    Promise.all([
      getChainList(),
      getChainAssetsList()
    ]).then(([chain, chainAsset]) => {
      setListChain(chain);
      setListAsset(chainAsset)
    }).catch((e)=>{
      throw new Error((e as Error).message)
    })
  }, [])


  return (
    <div className="App">
      <div className="box_container">
        <div className="box_item">
          <h3 className="box_item-title">
            Chain Number: 
          </h3>
          <div className="box_item-value">
            {listChainAPi.length}
          </div>
        </div>
        <div className="box_item">
          <h3 className="box_item-title">
             ChainEVM Number: 
          </h3>
          <div className="box_item-value">
            {listChainAPi.filter(({evmInfo})=>evmInfo).length}
          </div>
        </div>
        <div className="box_item">
          <h3 className="box_item-title">
             ChainSubstrate Number: 
          </h3>
          <div className="box_item-value">
            {listChainAPi.filter(({substrateInfo})=>substrateInfo).length}
          </div>
        </div>
        <div className="box_item">
          <h3 className="box_item-title">
            XCM Support List: 
          </h3>
          <div className="box_item-value">
            {listChainAsset.filter(({assetRefs})=>assetRefs.length > 0).length}
          </div>
        </div>
        <div className="box_item-listValue">
            {listChainAsset.filter(({assetRefs})=>assetRefs.length > 0).map(({assetRefs, name, slug}, index) => (
              <div key={index} className="item_list-value">
               <table className="table_list">
               <tr className="table_list-title">
                { index === 0 && 
                <>
                    <th>Name</th>
                    <th>Slug </th>
                    <th>type</th>
                    <th>DetAsset</th>
                </>
                }
              </tr>
                 {
                    assetRefs.map(({type,destAsset}, index) => (
                        <tr>
                          <th className="column_name">{index === 0 && name}</th>
                          <th className="column_name">{index === 0 && slug} </th>
                          <th className="column">{type} </th>
                          <th className="column">{destAsset}</th>
                        </tr>
                      ))
                  }
                </table>
              </div>
            ))}
          </div>
          <div className="box_item">
          <h3 className="box_item-title">
            Staking list: 
          </h3>
          <div className="box_item-value">
            {listChainAPi.filter(({substrateInfo})=>substrateInfo?.supportStaking).length}
          </div>
        </div>
        <div className="box_item-listValue">
            {listChainAPi.filter(({substrateInfo})=>substrateInfo?.supportStaking).map(({substrateInfo, name, slug}, index) => (
              <div key={index} className="item_list-value">
               <table className="table_list">
               <tr className="table_list-title">
                { index === 0 && 
                <>
                    <th>Name</th>
                    <th>Slug </th>
                    <th>Symbol</th>
                </>
                }
              </tr>
                 {
                        <tr>
                          <th className="column_name">{name}</th>
                          <th className="column_name">{slug} </th>
                          <th className="column">{substrateInfo?.symbol} </th>
                        </tr>
                  }
                </table>
              </div>
            ))}
          </div>
      </div>
    </div>
  );
}
